<template>
    <v-container class="px-0">
        <v-btn @click="generatePdf" small outlined>{{$t('pdf')}}</v-btn>
        <vue-excel-xlsx class="btnExcel" :data="itemsPayment" :columns="excelColumnsPayment" :filename="'payment-table'" :sheetname="'payments'">{{$t('excel')}}</vue-excel-xlsx>
        <v-data-table
            :headers="headersPayment"
            :items="itemsPayment"
            class="elevation-1"
        >
        </v-data-table>
    </v-container>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
    name:'Payments',
    data(){
        return{
            headersPayment:[
                {text:this.$t('title'),value:'title'},
                {text:this.$t('priceUsd'),value:'price'},
                {text:this.$t('paymentMode'),value:'paymentMode'},
                {text:this.$t('interval'),value:'interval'},
                {text:this.$t('assessment'),value:'assessmentCount'},
                {text:this.$t('responseAllAssessmentCount'),value:'responseAllAssessmentCount'},
                {text:this.$t('responsePerAssessmentCount'),value:'responsePerAssessmentCount'},
                {text:this.$t('startDate'),value:'startDate'},
                {text:this.$t('endDate'),value:'endDate'},
                {text:this.$t('userEmail'),value:'userEmail'},
                {text:this.$t('transactionEmail'),value:'transactionEmail'},
                {text:this.$t('transactionDetail'),value:'transactionDetail'},
                {text:this.$t('stripeSessionId'),value:'stripeSessionId'},  
                {text:this.$t('dateTime'),value:'dateAdded'},             
            ],
            itemsPayment:[],
            excelColumnsPayment : [
                {label:'Title',field:'title'},
                {label:'Price',field:'price'},
                {label:'Mode',field:'paymentMode'},
                {label:'Interval',field:'interval'},
                {label:'Assessment',field:'assessmentCount'},
                {label:'Date Start',field:'startDate'},
                {label:'Date End',field:'endDate'},
                {label:'Response(All Assessment)',field:'responseAllAssessmentCount'},
                {label:'Response(Per Assessment)',field:'responsePerAssessmentCount'},
                {label:'User Email',field:'userEmail'},
                {label:'Transaction Email',field:'transactionEmail'},
                {label:'Stripe Session Id',field:'stripeSessionId'},  
                {label:'Date-Time',field:'dateAdded'},             
           ],
        }
    },
    methods:{
        generatePdf(){
            const doc = new jsPDF({orientation:'l'})
            doc.autoTable({
                margin: { top: 10,left:2,right:1 },
                body: this.itemsPayment,
                columns: [
                    {header:'Title',dataKey:'title'},
                    {header:'Price',dataKey:'price'},
                    {header:'Mode',dataKey:'paymentMode'},
                    {header:'Interval',dataKey:'interval'},
                    {header:'Assessment',dataKey:'assessmentCount'},
                    {header:'Date Start',dataKey:'startDate'},
                    {header:'Date End',dataKey:'endDate'},
                    {header:'Response(All)',dataKey:'responseAllAssessmentCount'},
                    {header:'Response(Each)',dataKey:'responsePerAssessmentCount'},
                    {header:'User Email',dataKey:'userEmail'},
                    {header:'Transaction Email',dataKey:'transactionEmail'},
                    {header:'Stripe Session Id',dataKey:'stripeSessionId'},  
                    {header:'Date-Time',dataKey:'dateAdded'}, 
                ],
            })
            doc.save('Payments-table.pdf')
        },
        initializeAdmin(){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('settings/fetchPayment')
            .then((response)=>{
                //console.log(response.data)
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsPayment=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
    },
    created(){
        this.initializeAdmin()
    }
}
</script>